import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import { Box, Image, Text } from 'rebass/styled-components'
import { Label, Input } from '@rebass/forms/styled-components'

const InputGroup = ({ label, iconSrc, leftElement, rightElement, disabled, inputRef, sx, variant, ...props }) => {
  const [field, meta] = useField(props)
  const hasError = meta.touched && meta.error

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 2,
        height: 'max-content',
      }}
    >
      <Box sx={{ width: 'max-content', height: 'max-content' }}>
        {label && (
          <Label
            htmlFor={props.name || props.id}
            sx={{ mb: 2, fontSize: 6, fontWeight: 700 }}
          >
            {label}
          </Label>
        )}

        <Box
          variant={variant || 'inputGroupWrapper'}
          sx={{ bg: hasError ? '#FCE8E8' : null }}
        >
          {leftElement && leftElement}

          <Input
            variant="transparentInput"
            sx={{
              fontSize: 6,
              cursor: disabled ? 'not-allowed' : 'default',
              ...(sx || {}),
            }}
            disabled={disabled}
            ref={inputRef}
            {...field}
            {...props}
          />

          {iconSrc && (
            <Box>
              <Image src={iconSrc} alt="" aria-hidden />
            </Box>
          )}

          {rightElement && rightElement}
        </Box>
      </Box>

      {hasError && (
        <Text as="p" sx={{ color: 'dangerRed', fontSize: 4, lineHeight: 1.25 }}>
          {meta.error}
        </Text>
      )}
    </Box>
  )
}

InputGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  iconSrc: PropTypes.node,
  leftElement: PropTypes.any,
  rightElement: PropTypes.any,
  inputRef: PropTypes.node,
  sx: PropTypes.object,
  variant: PropTypes.string,
}

InputGroup.defaultProps = {
  label: null,
  id: null,
  autoComplete: null,
  placeholder: null,
  iconSrc: null,
  leftElement: null,
  rightElement: null,
  disabled: false,
  inputRef: null,
  sx: {},
  variant: null,
}

export default InputGroup
