import React from 'react'
import PropTypes from 'prop-types'
import { Box, Heading, Image, Text } from 'rebass/styled-components'

import Container from './container'
import ContactLinks from './contact-links'
import Illustration from '../images/hero-illustration.svg'

const Hero = ({ title }) => {
  const titleType = typeof title

  return (
    <Box variant="hero">
      <Container>
        <Box variant="hero.body">
          <Box variant="hero.content">
            {titleType === 'string' && (
              <Heading
                as="h1"
                variant="hero.title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}

            {titleType === 'object' && (
              <Heading as="h1" variant="hero.title">
                {title}
              </Heading>
            )}

            <Box
              sx={{
                display: 'grid',
                gap: 3,
                gridAutoFlow: ['row', 'column', 'row'],
              }}
            >
              <Box
                sx={{ display: 'grid', gap: [3, 2, 3], alignContent: 'start' }}
              >
                <Text sx={{ fontSize: 7 }}>Kontaktujte nás:</Text>

                <ContactLinks />
              </Box>
            </Box>
          </Box>

          <Image src={Illustration} alt="" variant="hero.illustration" />
        </Box>
      </Container>
    </Box>
  )
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Hero
