import React from 'react'
import PropTypes from 'prop-types'
import { Box, Card, Heading, Text } from 'rebass/styled-components'

const Benefit = ({ title, text, subtext, icon }) => {
  return (
    <Card
      sx={{
        p: '1.5rem',
        display: 'grid',
        gap: 4,
        alignContent: 'start',
      }}
    >
      <Box>{icon && icon}</Box>

      <Box
        sx={{
          display: 'grid',
          gap: 3,
        }}
      >
        <Heading as="h2" sx={{ fontSize: 8 }}>
          {title}
        </Heading>

        <Text as="p" sx={{ fontSize: 6, lineHeight: 1.5 }}>
          {text}
        </Text>

        <Text as="p" sx={{ color: '#a04545', fontSize: 5, lineHeight: 'body' }}>
          {subtext}
        </Text>
      </Box>
    </Card>
  )
}

Benefit.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtext: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
}

export default Benefit
