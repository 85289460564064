// Source: https://codepen.io/nzbin/pen/GGrXbp

import React from 'react'
import { Box } from 'rebass/styled-components'
import styled, { keyframes } from 'styled-components'

const dotFlashing = keyframes`
  0% {
    background-color: #A8A1E2;
  }
  50%,
  100% {
    background-color: #fff;
  }
`

const Dots = styled.div`
  position: relative;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  background-color: #A8A1E2;
  color: #A8A1E2;
  animation: ${dotFlashing} 0.5s infinite linear alternate;
  animation-delay: 0.25s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.5rem;
  }

  &::before {
    left: -1rem;
    background-color: #A8A1E2;
    color: #A8A1E2;
    animation: ${dotFlashing} 0.5s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 15px;
    background-color: #A8A1E2;
    color: #A8A1E2;
    animation: ${dotFlashing} 0.5s infinite alternate;
    animation-delay: 0.5s;
  }
`

const LoadingDots = () => {
  return (
    <Box sx={{ height: '1rem', display: 'grid', alignContent: 'center' }}>
      <Dots />
    </Box>
  )
}

export default LoadingDots
