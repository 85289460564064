import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Formik, Form } from 'formik'
import { Box, Button, Card, Text } from 'rebass/styled-components'

import validationSchema from './contact-form-validation'
import InputGroup from './input-group'
import TextareaGroup from './textarea-group'
import LoadingDots from './loading-dots'
import IconUser from '../images/system-uicons_user-male-circle.svg'
import IconEmail from '../images/system-uicons_mail.svg'
import IconPhone from '../images/system-uicons_iphone-portrait.svg'

const ContactForm = () => {
  const [formState, setFormState] = useState({
    error: null,
    success: false,
  })

  const resetError = () => {
    setFormState({
      ...formState,
      error: null,
    })
  }

  const resetSuccess = () => {
    setFormState({
      ...formState,
      success: false,
    })
  }

  const sendMail = (values, { resetForm, setSubmitting }) => {
    setSubmitting(true)

    fetch('/.netlify/functions/contact-form', {
      method: 'POST',
      body: JSON.stringify({
        fullname: values.fullname,
        email: values.email,
        phone: values.phone,
        address: values.address,
        description: values.description,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setFormState({
            error: null,
            success: response,
          })
          resetForm()
          setTimeout(resetSuccess, 5000)
        }
      })
      .catch((error) => {
        console.log(error)
        setFormState({
          error: 'Odeslání zprávy selhalo.',
          success: false,
        })
      })
      .finally(() => {
        setTimeout(() => {
          if (formState.success === false) {
            setFormState({
              error: null,
              success: 'Zpráva odeslána!',
            })
            resetForm()
            setSubmitting(false)
            setTimeout(resetSuccess, 5000)

            // Facebook pixel conversion tracker
            if (typeof window !== 'undefined' && window.fbq != null) {
              window.fbq('track', 'Lead')
            }

            // Google Tag conversion tracker
            if (typeof window !== 'undefined' && window.gtag) {
              window.gtag('event', 'conversion', {
                send_to: 'AW-395202603/PjrSCNDh9_0BEKugubwB',
                event_callback: () => {},
              })
            }
          }
        }, 3000)
      })
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: 'max-content',
      }}
    >
      <Card
        sx={{
          p: [3, 3, 4],
          width: 'max-content',
          boxShadow: 'bigShadow',
        }}
      >
        <Formik
          initialValues={{
            fullname: '',
            email: '',
            phone: '',
            address: '',
            description: '',
          }}
          validationSchema={validationSchema}
          onSubmit={sendMail}
        >
          {({ isSubmitting }) => (
            <Form>
              <Box sx={{ display: 'grid', gap: '24px', width: 'max-content' }}>
                <InputGroup
                  name="fullname"
                  id="fullname"
                  label="Celé jméno"
                  type="text"
                  placeholder="Jan Novák"
                  autoComplete="name"
                  disabled={isSubmitting}
                  iconSrc={IconUser}
                  onFocus={resetError}
                />

                <InputGroup
                  name="email"
                  id="email"
                  label="E-mail"
                  type="email"
                  placeholder="vas@email.cz"
                  autoComplete="email"
                  disabled={isSubmitting}
                  iconSrc={IconEmail}
                  onFocus={resetError}
                />

                <InputGroup
                  name="phone"
                  id="phone"
                  label="Telefon"
                  type="tel"
                  placeholder="+420 123 123 123"
                  autoComplete="tel"
                  disabled={isSubmitting}
                  iconSrc={IconPhone}
                  onFocus={resetError}
                />

                <TextareaGroup
                  name="address"
                  id="address"
                  label="Adresa odtahu"
                  rows={4}
                  disabled={isSubmitting}
                  placeholder="Adresa odkud budeme vozidlo odtahovat"
                  onFocus={resetError}
                />

                <TextareaGroup
                  name="description"
                  id="description"
                  label="Stručný popis vozidla"
                  rows={4}
                  disabled={isSubmitting}
                  placeholder="Stav, chybějící části apod."
                  onFocus={resetError}
                />

                <Button type="submit" variant="primary" disabled={isSubmitting}>
                  {isSubmitting ? <LoadingDots /> : 'Odeslat'}
                </Button>

                {formState.error && (
                  <Text as="p" variant="errorMessage">
                    {formState.error}
                  </Text>
                )}

                <Text
                  as="p"
                  sx={{
                    color: '#a04545',
                    fontSize: 4,
                    textAlign: 'center',
                    maxWidth: '292px',
                  }}
                >
                  Odesláním svých kontaktních souhlasíte se&nbsp;
                  <Link to="/zpracovani-osobnich-udaju">
                    zpracováním osobních údajů
                  </Link>
                  .
                </Text>
              </Box>
            </Form>
          )}
        </Formik>
      </Card>

      {formState.success && (
        <Box
          onClick={resetSuccess}
          sx={{
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bg: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '12px',
            color: 'green',
            fontSize: 7,
            fontWeight: 700,
          }}
        >
          {typeof formState.success === 'string'
            ? formState.success
            : 'Zpráva odeslána!'}
        </Box>
      )}
    </Box>
  )
}

export default ContactForm
