import React from 'react'
import PropTypes from 'prop-types'
import { Box, Image, Link } from 'rebass/styled-components'

import IconFacebook from '../images/icon-facebook.svg'
import IconInstagram from '../images/icon-instagram.svg'
import IconIPhone from '../images/icon-iphone.svg'
import IconEmail from '../images/icon-email.svg'

const ContactLink = ({ children, href, ...rest }) => (
  <Link
    href={href}
    sx={{
      width: 'max-content',
      height: 'max-content',
      p: 3,
      bg: 'white',
      borderRadius: '4px',
      boxShadow: 'bigShadow',
      lineHeight: 0,
      transition: 'all 200ms ease-out',
      '&:hover': {
        boxShadow: 'bigShadowHover',
        transform: 'translateY(-0.25rem)',
        zIndex: 1,
      },
      '&:active': {
        boxShadow: 'bigShadow',
        transform: 'translateY(0)',
        zIndex: 1,
      },
    }}
    {...rest}
  >
    {children}
  </Link>
)

const ContactLinks = () => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        gap: 3,
        justifyContent: 'start',
      }}
    >
      <ContactLink href="tel:+420777800483" title="Telefon">
        <Image src={IconIPhone} alt="Telefon" />
      </ContactLink>

      <ContactLink href="mailto:info@autovrakyhrubes.cz" title="E-mail">
        <Image src={IconEmail} alt="E-mail" />
      </ContactLink>

      <ContactLink
        href="https://www.facebook.com/autovrakyhrubes"
        target="_blank"
        title="Facebook"
      >
        <Image src={IconFacebook} alt="Facebook" />
      </ContactLink>

      <ContactLink
        href="https://www.instagram.com/autovrakyhrubes/"
        target="_blank"
        title="Instagram"
      >
        <Image src={IconInstagram} alt="Intagram" />
      </ContactLink>
    </Box>
  )
}

ContactLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
}

export default ContactLinks
