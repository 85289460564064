import * as Yup from 'yup'
import 'yup-phone'

const requiredString = 'Tato položka je povinná.'

export default Yup.object({
  fullname: Yup.string()
    .max(64, 'Celé jméno nesmí být delší než 64 znaků.')
    .required(requiredString),
  email: Yup.string()
    .email('Nesprávný formát e-mailové adresy.')
    .required(requiredString),
  phone: Yup.string()
    // .phone('CZ', true, 'Nesprávný formát telefonního čísla.')
    .required(requiredString),
  address: Yup.string().required(requiredString),
  description: Yup.string().required(requiredString),
})
