import React from 'react'
import { Box, Image, Link } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Benefits from '../components/benefits'
import ContactFormSection from '../components/contact-form-section'
import Benefit from '../components/benefit'
import BenefitIcon from '../components/benefit-icon'
import IconThumbsUp from '../images/icon-thumbs-up.svg'
import IconLocation from '../images/system-uicons_location.svg'
import IconIPhone from '../images/icon-iphone.svg'

const IndexPage = () => {
  return (
    <Layout
      headerTitle="Autovraky Hrubeš"
      footerTitle="Ekologická likvidace vozidel Ostrava"
    >
      <SEO
        title="Autovraky Hrubeš"
        description="Ekologická likvidace vozidel Ostrava"
      />

      <Hero
        title={
          <Box>
            <Box as="span" sx={{ color: 'accent' }}>Vykupujeme všechny vozy</Box> určené
            k&nbsp;likvidaci!
          </Box>
        }
      />

      <Benefits>
        <Benefit
          title="Výhody využití ekologické likvidace vozidel"
          text={
            <Box>
              <Box as="ul" sx={{ listStyle: 'disc', pl: 4 }}>
                <li>odtah zdarma</li>
                <li>protokol o likvidaci vozu samozřejmostí</li>
                <li>
                  vozidlo za Vás zdarma odhlásíme z registru silničních vozidel
                </li>
                <li>nejvyšší výkupní ceny za autovraky</li>
                <li>
                  minimálně 1500,- za každé vozidlo určené k ekologické
                  likvidaci
                </li>
              </Box>
            </Box>
          }
          subtext={
            <Box>
              <Link href="tel:+420777800483" variant="phoneLink">
                <Image src={IconIPhone} alt="Telefon" aria-hidden />
                Zavolejte nám
              </Link>
            </Box>
          }
          icon={<BenefitIcon iconSrc={IconThumbsUp} variant="iconDefault" />}
        />

        <Benefit
          title="Přijedeme za Vámi do těchto měst a jejich okolí"
          text="Ostrava, Opava, Jeseník, Olomouc, Šumperk, město Albrechtice, Krnov, Bruntál, Bílovec, Mošnov, Orlová, Karviná či Česky Těšín."
          subtext="Na poptávce likvidace v jiné lokalitě je možnost se domluvit."
          icon={<BenefitIcon iconSrc={IconLocation} variant="iconDefault" />}
        />
      </Benefits>

      <ContactFormSection />
    </Layout>
  )
}

export default IndexPage
