import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import { Box, Text } from 'rebass/styled-components'
import { Label, Textarea } from '@rebass/forms/styled-components'

const TextAreaGroup = ({ label, disabled, ...props }) => {
  const [field, meta] = useField(props)
  const hasError = meta.touched && meta.error

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 2,
        height: 'max-content',
      }}
    >
      <Box sx={{ width: 'max-content', height: 'max-content' }}>
        <Label
          htmlFor={props.name || props.id}
          sx={{ mb: 2, fontSize: 6, fontWeight: 700 }}
        >
          {label}
        </Label>

        <Textarea
          variant="transparentInput"
          sx={{
            p: 3,
            display: 'block',
            fontSize: 6,
            resize: 'none',
            bg: hasError ? '#FCE8E8' : '#F9FAFB',
            borderRadius: '12px',
            cursor: disabled ? 'not-allowed' : 'default',
            maxWidth: ['240px', '292px', '292px'],
          }}
          {...field}
          {...props}
        />
      </Box>

      {hasError && (
        <Text as="p" sx={{ color: 'dangerRed', fontSize: 4, lineHeight: 1.25 }}>
          {meta.error}
        </Text>
      )}
    </Box>
  )
}

TextAreaGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}

TextAreaGroup.defaultProps = {
  placeholder: null,
  disabled: false,
}

export default TextAreaGroup
