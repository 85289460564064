import React from 'react'
import PropTypes from 'prop-types'
import { Box, Image } from 'rebass/styled-components'

const BenefitIcon = ({ iconSrc, variant }) => {
  return (
    <Box variant={`benefits.${variant}`}>
      <Image src={iconSrc} alt="" aria-hidden variant="benefits.iconImage" />
    </Box>
  )
}

BenefitIcon.propTypes = {
  iconSrc: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  variant: PropTypes.oneOf(['iconSell', 'iconOffer', 'iconLaw']),
}

export default BenefitIcon
