import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box } from 'rebass/styled-components'

const ContactImages = () => {
  const data = useStaticQuery(graphql`
    {
      images: allFile(
        filter: { relativePath: { regex: "/^image-/" } }
        sort: { fields: relativePath, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            gatsbyImageData(width: 480, layout: FIXED)
          }
        }
      }
    }
  `)
  const images = data.images.nodes

  return (
    <Box
      sx={{
        display: 'grid',
        alignItems: 'center',
        justifyContent: ['center', 'center', 'right'],
        maxWidth: '100%',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr', // 366
          // gridTemplateRows: `repeat(${images.length}, 1fr)`, // 494
          gap: 3,
        }}
      >
        {images &&
          images.map((image, i) => {
            const boxShadow = '0 16px 48px rgba(121, 121, 172, 0.25)'
            const left = i % 2 === 1 ? '-4rem' : 0

            return (
              <Box as={GatsbyImage}
                key={image.id}
                image={image.childImageSharp.gatsbyImageData}
                alt=""
                sx={{
                  maxWidth: 'calc(100vw - 2rem)',
                  position: 'relative',
                  left: [0, 0, left],
                  top: [0, 0, `-${i * 2}rem`],
                  borderRadius: '4px',
                  boxShadow,
                }}
              />
            )
          })}
      </Box>
    </Box>
  )
}

export default ContactImages
