import React from 'react'
import { Box, Heading } from 'rebass/styled-components'

import Container from './container'
import ContactForm from './contact-form'
import ContactImages from './contact-images'

const ContactFormSection = () => {
  return (
    <Box sx={{ py: 5, bg: '#F9FAFB' }}>
      <Container>
        <Heading as="h2" variant="sectionTitle" sx={{ mb: 4 }}>
          Nacenění vozidla
        </Heading>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr', 'auto 1fr'],
            justifyItems: ['start', 'center', 'normal'],
            gap: [4, 5, 0],
          }}
        >
          <ContactForm />

          <ContactImages />
        </Box>
      </Container>
    </Box>
  )
}

export default ContactFormSection
