import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'

import Container from './container'

const BenefitsList = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gap: '30px',
        gridAutoFlow: ['row', 'row', 'column'],
      }}
    >
      {children}
    </Box>
  )
}

const Benefits = ({ children }) => {
  return (
    <Box sx={{ py: 5, bg: '#F9FAFB' }}>
      <Container>
        <BenefitsList>
          {children}
        </BenefitsList>
      </Container>
    </Box>
  )
}

Benefits.propTypes = {
  children: PropTypes.node.isRequired,
}

BenefitsList.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Benefits
